<template>
  <div>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="selectedHeaders.header"
      :items="items"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:top  v-if="this.adminAccess==='Super Admin'||this.adminAccess=='Sales Representative' ||this.adminAccess=='Event Support Staff' ">
        <div class="pt-5">
          <div class="tab-navigation">
            <v-icon class="btn" @click="prevTab" :disabled="selected === 0">mdi-arrow-left</v-icon>
            <v-icon class="btn" @click="nextTab" :disabled="selected === tabs.length - 1">mdi-arrow-right</v-icon>
          </div>
          <v-tabs
            v-model="selected"
            grow
            background-color="transparent"
            color="red"
            class="px-20"
          >
            <v-tab
              v-for="(item, i) in tabs"
              :key="i"
              class="text-capitalize px-6"
              style="margin-left: 40px"
            >{{item.name}}</v-tab>
          </v-tabs>
          <b><hr style="margin-bottom: 5px" /></b>
        </div>
        <div class="d-flex justify-end pa-4 pt-2">
          <v-btn
            class="text-capitalize"
            height="44"
            dark
            color="#2C1963"
            style="border-radius: 10px"
            v-if="tabsSelectedValue === 'voucher'"
            @click="createVoucher()"
            >Bulk Voucher</v-btn
          >&nbsp;&nbsp;
          <v-btn
            class="text-capitalize"
            height="44"
            dark
            color="#2C1963"
            style="border-radius: 10px"
            @click="openModal"
          >
            <span>{{ selectedHeaders.text }}</span>
          </v-btn>
        </div>
      </template>

        <template v-slot:top  v-else>
        <div class="pt-5">
          <v-tabs
            v-model="selected"
            grow
            mobile-breakpoint
            background-color="transparent"
            color="red"
            class="px-20"
          >
            <v-tab
              v-for="(item, i) in tabs"
              :key="i"
              class="text-capitalize px-6"
              v-text="item.name"
              style="margin-left: 40px"
            ></v-tab>
          </v-tabs>
          <b><hr style="margin-bottom: 5px" /></b>
        </div>
      </template>
      <template v-slot:item="props" v-if="tabsSelectedValue === 'volunteers'">
        <tr>
          <td class="text-center">
            <div>{{ props.item.name }}</div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.email }}
            </div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.phone }}
            </div>
          </td>
         <td class="text-center">
            <div>
              {{ props.item.game_designation }}
            </div>
          </td>
          <td class="text-center py-4">
        <!--    <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleVolunteerModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>-->
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.committee_id })"
          >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
             <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="resendInvitation(props.item)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>Resend Invitation</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:item="props" v-else-if="tabsSelectedValue === 'assembly'">
        <tr>
          <td class="text-center">
            <div class="tableText">{{ props.item.school_name }}</div>
            <div style="font-size: 12px; color: #757575">
              {{ props.item.school }}
            </div>
          </td>
          <td class="text-center">
            <div class="tableText">
              {{ props.item.assemble_date_2 }},{{ props.item.start_time_str }}
            </div>
          </td>
          <td class="text-center py-2">
            <div class="tableText">
              {{ props.item.name }}
            </div>
            <div style="font-size: 12px; color: #757575">
              <div>{{ props.item.phone }}</div>
              <div>{{ props.item.email }}</div>
            </div>
          </td>
           <td class="text-center">
           &nbsp;&nbsp;&nbsp; <div  v-if="props.item.user_type=='admin' ">  <v-switch
                color="#067605"
                v-model="props.item.approved"
                @change="changeStatus(props.item.approved, props.item.id)"
             >
                <template v-slot:label>
                  <div
                    :style="
                      props.item.approved ? 'color:#067605' : 'color:#8C8C8C'
                    "
                  >
                    <div v-if="props.item.approved">Approved</div>
                    <div v-else>Not Approved</div>
                  </div>
                </template>
              </v-switch>
            </div>
            <div  v-else>  <v-switch
                color="#067605"
                v-model="props.item.approved"
                @change="changeStatus(props.item.approved, props.item.id)"
             disabled>
                <template v-slot:label>
                  <div
                    :style="
                      props.item.approved ? 'color:#067605' : 'color:#8C8C8C'
                    "
                  >
                    <div v-if="props.item.approved">Approved</div>
                    <div v-else>Not Approved</div>
                  </div>
                </template>
              </v-switch>
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleAssemblyModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
           v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
            v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:item="props" v-else-if="tabsSelectedValue === 'sponsors'">
        <tr>
          <td class="text-center py-2">
            <div class="d-flex justify-center">
              <div>
                <img :src="`${props.item.sponsor_logo}`" width="70" alt="" />
              </div>
              <div class="ml-3">
                <div class="tableText">{{ props.item.sponsor_name }}</div>
                <div style="font-size: 12px; color: #2e6ce3">
                  {{ props.item.website }}
                </div>
              </div>
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleSponsorModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
           v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
           v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:item="props" v-else-if="tabsSelectedValue === 'hotel'">
        <tr>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.name }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.hotel_address }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.confirmation_number }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.checking_in_date_2 }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.checking_out_date_2 }}
            </div>
          </td>
           <td class="text-center">
            <div >  <v-switch
                color="#067605"
                v-model="props.item.approved"
                @change="changeHotelStatus(props.item.approved, props.item.id)"
             >
                <template v-slot:label>
                  <div
                    :style="
                      props.item.approved ? 'color:#067605' : 'color:#8C8C8C'
                    "
                  >
                    <div v-if="props.item.approved">Approved</div>
                    <div v-else>Not Approved</div>
                  </div>
                </template>
              </v-switch>
            </div>
            
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleHotelInfoModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
           v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
          v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'"  >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <template v-slot:item="props" v-else-if="tabsSelectedValue === 'note'">
        <tr>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.note.substring(0, 60) }}...
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.created_by }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.created_on }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.created_at }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleNoteModal({
                    show: true,
                    type: 'view',
                    id: props.item.id,
                  })
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </v-chip>
               
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:item="props" v-else-if="tabsSelectedValue === 'voucher'">
        <tr>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.voucher_code }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.maximum_usages }}
            </div>
          </td>

          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.product_name }}
            </div>
          </td>
          <td class="text-center">
            <div
              style="font-size: 12px; color: #757575"
              v-if="props.item.price_mode == 'subtract'"
            >
              Subtract from product price
            </div>
            <div
              style="font-size: 12px; color: #757575"
              v-if="props.item.price_mode == 'set'"
            >
              Set product price to
            </div>
            <div
              style="font-size: 12px; color: #757575"
              v-if="props.item.price_mode == 'none'"
            >
              No effect
            </div>
            <div
              style="font-size: 12px; color: #757575"
              v-if="props.item.price_mode == 'percent'"
            >
              Reduce product price by(%)
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.value }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleVoucherModal({
                    show: true,
                    type: 'edit',
                    id: props.item.id,
                  })
                "
             v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'" >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:item="props" v-else>
        <tr class="py-3">
          <td class="text-center">
            <div>{{ props.item.name }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.email }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.phone }}</div>
          </td>
          <td class="text-center">
            <div>{{ props.item.tshirt_size }}</div>
          </td>
          <td class="text-center pt-3">
            <div class="d-flex justify-center">
              <v-switch
                style="margin-top: -2px"
                color="#067605"
                disabled
                v-model="props.item.is_confirmed"
              >
                <template v-slot:label>
                  <div
                    v-if="props.item.is_confirmed"
                    style="color: #067605; font-size: 14px"
                  >
                    Confirmed
                  </div>
                  <div v-else style="font-size: 14px; color: #8c8c8c">
                    Not Confirmed
                  </div>
                </template>
              </v-switch>
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleHomeTeamModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
           v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.id })"
          v-if="props.item.user_type=='admin' ||props.item.user_type=='Sales Representative'||props.item.user_type=='Event Support Staff'"  >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <sponsor-modal @reload="getData()"></sponsor-modal>
    <delete-modal @reload="deleteData()"></delete-modal>
    <assembly-schedual-modal @reload="getData()"></assembly-schedual-modal>
    <volunteer-modal @reload="getData()"></volunteer-modal>
    <home-team-modal @reload="getData()"></home-team-modal>
    <hotel-info-modal @reload="getData()"></hotel-info-modal>
    <note-modal></note-modal>
    <voucher-modal></voucher-modal>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import {
  GAME_API_SPONSOR_LIST,
  GAME_API_SPONSOR_DELETE,
  ASSEMBLY_SCHEDUAL_API_LIST,
  ASSEMBLY_SCHEDUAL_API_DELETE,
  VOLUNTEER_API_LIST,
  API_GAME_COMMITTEE_LIST,
  VOLUNTEER_API_DELETE,
  API_GAME_COMMITTEE_DELETE,
  API_HOME_TEAM_LIST,
  API_HOME_TEAM_DELETE,
  GAME_HOTEL_GET_LIST_API,
  NOTE_SECTION_GET_LIST_API,
  API_GET_LIST_GAME_VOUCHER,
  GAME_HOTEL_DELETE_API,
  API_CREATE_VOUCHER,
  API_ADD_ASSEMBLY_CALENDAR,
  API_ADD_HOTEL_CALENDAR,
} from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GameDashboardVASH",
  components: {
    DeleteModal: () => import("../DeleteModal/DeleteModal"),
    SponsorModal: () => import("./SponsorModal/SponsorModal"),
    AssemblySchedualModal: () =>
      import("./AssemblySchedualModal/AssemblySchedualModal"),
    VolunteerModal: () => import("./VolunteerModal/VolunteerModal"),
    HomeTeamModal: () => import("./HomeTeamModal/HomeTeamModal"),
    HotelInfoModal: () => import("../HotelInfoModal/HotelInfoModal"),
    NoteModal: () => import("./NoteModal/NoteModal"),
    VoucherModal: () => import("./VoucherModal/VoucherModal"),
  },
  data() {
    return {
    adminAccess:localStorage.getItem("adminAccess"),
      loading: false,
      volunteersHeader: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Email",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Mobile",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
       {
         sortable: false,
         text: "Designation",
         align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
       },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      AssemblyHeaders: [
        {
          sortable: false,
          text: "School Info",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Date/Time",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Point of contact",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
            {
          sortable: false,
          text: "Status",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      sponsorsHeaders: [
        {
          sortable: false,
          text: "Sponsor Info",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      homeTeamHeaders: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Email",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Mobile",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Tshirt size",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Confirmed",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      hotelHeaders: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Address",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Confirmation Number",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Check in date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Check out date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
                {
          sortable: false,
          text: "Status",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      // noteHeaders: [
      //   {
      //     sortable: false,
      //     text: "Note",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Note By",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Date",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Time",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Action",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      // ],
      // voucherHeaders: [
      //   {
      //     sortable: false,
      //     text: "Voucher Code",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Maximum Usage",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Product",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Price Mode",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Value",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      //   {
      //     sortable: false,
      //     text: "Action",
      //     align: "center",
      //     class: ["tableHeader-text", "tableHeader-bg"],
      //   },
      // ],
      selected: 0,
      tabs: [
    
        { name: "Assembly Schedule", value: "assembly" },
        { name: "Hotel", value: "hotel" },
        { name: "Committee", value: "volunteers" },
        { name: "Sponsors", value: "sponsors" },
        { name: "Home Team", value: "homeTeam" },
        { name: "Voucher", value: "voucher" },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      selectGameData: "gameManagement/getGame",
    }),
    selectedHeaders() {
      let value = this.tabs[this.selected].value;
      switch (value) {
        case "volunteers":
          return { header: this.volunteersHeader, text: "Add Committee" };
        case "assembly":
          return {
            header: this.AssemblyHeaders,
            text: "Add Assembly Schedule",
          };
        case "sponsors":
          return { header: this.sponsorsHeaders, text: "Add Sponsor" };
        case "homeTeam":
          return { header: this.homeTeamHeaders, text: "Add Team Member" };
        case "hotel":
          return { header: this.hotelHeaders, text: "Add Hotel" };
        case "voucher":
          return { header: this.voucherHeaders, text: "Add Voucher" };
        default:
          return { header: this.volunteersHeader, text: "Add Volunteer" };
      }
    },
    tabsSelectedValue() {
      return this.tabs[this.selected].value;
    },
  },
  watch: {
    selected() {
      this.getData();
    },
  },
   created() {
    this.$root.$refs.gameDashboardVASH= this;
  },
  methods: {
    nextTab() {
      if (this.selected < this.tabs.length - 1) {
        this.selected++;
      }
    },
    prevTab() {
      if (this.selected > 0) {
        this.selected--;
      }
    },
    ...mapActions({
      toggleSponsorModal: "gameManagement/toggleSponsor",
      toggleDeleteModal: "deleteManagement/toggleModal",
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      toggleVolunteerModal: "gameManagement/toggleVolunteer",
      showToast: "snackBar/showToast",
      toggleHomeTeamModal: "gameManagement/toggleHomeTeam",
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      toggleNoteModal: "gameManagement/toggleNoteModal",
      toggleVoucherModal: "gameManagement/toggleVoucherModal",
    }),
    openModal() {
      switch (this.tabsSelectedValue) {
        case "volunteers":
          this.toggleVolunteerModal({ show: true, type: "add" });
          break;
        case "assembly":
          this.toggleAssemblyModal({ show: true, type: "add" });
          break;
        case "sponsors":
          this.toggleSponsorModal({ show: true, type: "add" });
          break;
        case "homeTeam":
          this.toggleHomeTeamModal({ show: true, type: "add" });
          break;
        case "hotel":
          this.toggleHotelInfoModal({ show: true, type: "add" });
          break;
        case "note":
          this.toggleNoteModal({ show: true, type: "add" });
          break;
        case "voucher":
          this.toggleVoucherModal({ show: true, type: "add" });
          break;
        default:
          break;
      }
    },
    getData() {
      this.items = [];
      switch (this.tabsSelectedValue) {
        case "volunteers":
          this.getCommitteeData();
          break;
        case "assembly":
          this.getAssemblyData();
          break;
        case "sponsors":
          this.getSponsorsData();
          break;
        case "homeTeam":
          this.getHomeTeamData();
          break;
        case "hotel":
          this.getHotelData();
          break;
        case "voucher":
          this.getVoucherData();
          break;
      }
    },

    deleteData() {
      switch (this.tabsSelectedValue) {
        case "volunteers":
          this.deleteCommitteeData();
          break;
        case "assembly":
          this.deleteAssemblyData();
          break;
        case "sponsors":
          this.deleteSponsorsData();
          break;
        case "homeTeam":
          this.deleteHomeTeamData();
          break;
        case "hotel":
          this.deleteHotelData();
          break;
      }
    },
    getVolunteersData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.volunteer_detail;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        VOLUNTEER_API_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
     getCommitteeData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.team_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_GAME_COMMITTEE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getHotelData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.hotel_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_HOTEL_GET_LIST_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getNoteData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.note_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        NOTE_SECTION_GET_LIST_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getVoucherData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.voucher_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_GET_LIST_GAME_VOUCHER,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getAssemblyData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.assemble_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getSponsorsData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.sponsor_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_API_SPONSOR_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getHomeTeamData() {
      this.loading = true;
      const successHandler = (res) => {
        // console.log(res.data)
        this.items = res.data.home_team_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_HOME_TEAM_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    deleteVolunteersData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        VOLUNTEER_API_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
     deleteCommitteeData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        API_GAME_COMMITTEE_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteAssemblyData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        ASSEMBLY_SCHEDUAL_API_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteSponsorsData() {
      console.log("spo delete");
      console.log(this.$store.state.deleteManagement.deleteModal.deleteID);
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        GAME_API_SPONSOR_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteHomeTeamData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        API_HOME_TEAM_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteHotelData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        GAME_HOTEL_DELETE_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    pageReload() {
      location.reload();
    },
    createVoucher() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.showToast({
          message: "Created Successfully",
          color: "s",
        });
        location.reload();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_POST(
        API_CREATE_VOUCHER,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
       resendInvitation(item) {
      const successHandler = () => {
        this.showToast({
          message: "Invitation sent successfully.",
          color: "s",
        });
      };

      const failureHandler = () => {};
      let formData = {};
      formData["user_id"] = item.id;
      formData["id"]=this.$route.query.game_id;
      Axios.request_GET(
        "/game/resend_email/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

      changeStatus(status, id) {
      console.log(status, id);
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = id;
      formData["status"] = status;
      Axios.request_PATCH(
        API_ADD_ASSEMBLY_CALENDAR,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
     changeHotelStatus(status, id) {
      console.log(status, id);
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = id;
      formData["status"] = status;
      Axios.request_PATCH(
        API_ADD_HOTEL_CALENDAR,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.tableText {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 25px */

  color: #1d1d1d;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}

.tab-navigation {
  display: flex;
  justify-content: space-between;
}

.tab-navigation .btn {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 25px;
}
</style>
